// SuccessFeedbackToast.jsx
import React from 'react';
import { ReactComponent as ToastSuccess } from 'assets/icons/toast-success.svg';
import FeedbackToast from 'components/atoms/FeedbackToast/FeedbackToast';
import { AFFINITY_BASE_URL } from 'utils/constants';

interface SuccessFeedbackToastProps {
  hideToast: () => void;
  hiding: boolean;
  personalAffinityListId: string;
}

const SuccessMessage = ({ listId }: { listId: string }) => (
  <p>
    This company has been included in your{' '}
    <a href={`${AFFINITY_BASE_URL}lists/${listId}`} target="_blank" rel="noreferrer">
      Affinity List
    </a>
  </p>
);

const SuccessFeedbackToast: React.FC<SuccessFeedbackToastProps> = ({
  hideToast,
  hiding,
  personalAffinityListId,
}) => {
  return (
    <FeedbackToast
      message={<SuccessMessage listId={personalAffinityListId} />}
      icon={<ToastSuccess />}
      primaryColor="#089983"
      secondaryColor="#e7f5f2"
      onClose={hideToast}
      hiding={hiding}
    />
  );
};

export default SuccessFeedbackToast;
