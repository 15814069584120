import httpClient from 'common/http/httpClient';
import { User } from './user.model';

interface Response {
  data: { data: any };
}

export interface IUserService {
  getMe: () => Promise<User>;
  getAvailableUsers: () => HttpPromise<User[]>;
}

const UserService = (): IUserService => {
  return {
    getMe: async (): Promise<User> => {
      const response = (await httpClient.get(`/sourcer/me`)) as Response;
      return response.data?.data as User;
    },

    getAvailableUsers: async (): Promise<User[]> => {
      const response = (await httpClient.get(`/sourcer/all`)) as Response;
      return response.data?.data as User[];
    },
  };
};

export default UserService();
