import httpClient from 'common/http/httpClient';
import {
  Company,
  ICompanyService,
  EventData,
  DataFundraising,
  UndoReviewData,
  HeadlineFeedbackData,
} from './company.model';

interface Response {
  data: { data: any };
}

const CompanyService = (): ICompanyService => {
  return {
    getOneCompany: async (companyId: string): Promise<Company> => {
      const response = (await httpClient.get(`/company/${companyId}`)) as Response;
      return response.data.data as Company;
    },

    getAllCompanies: async (): Promise<string[]> => {
      const response = (await httpClient.get(`/company/all`)) as Response;
      return response.data.data as string[];
    },

    submitEvent: async (data: EventData): Promise<any> => {
      const response = (await httpClient.post(`/company/event`, { data })) as Response;
      return response.data.data as any;
    },

    submitHeadlineFeedback: async (data: HeadlineFeedbackData): Promise<any> => {
      const response = (await httpClient.post(`/headline/feedback/${data.headlineId}`, {
        data,
      })) as Response;
      return response.data.data as any;
    },

    undoReview: async (data: UndoReviewData): Promise<any> => {
      const { sourcerId, companyId } = data;
      const response = (await httpClient.delete(
        `/sourcer/${sourcerId}/undo-event/${companyId}`,
      )) as Response;

      return response.data.data as any;
    },

    getFundraisingData: async ({ snapshotId, companyId, page }: DataFundraising): Promise<any> => {
      const response = (await httpClient.get(
        `/company/deals?snapshotId=${snapshotId}&companyId=${companyId}&page=${page}`,
      )) as Response;
      return response.data.data as any;
    },
  };
};

export default CompanyService();
